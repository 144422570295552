import { Guid } from '../types';

export interface Template {
	id: number;
	name: string;
	description: string;
	created: string;
	createdBy: string;
	modified: string;
	modifiedBy: string;
	rdl: string;
	type: string;
}

export interface TemplateAddResponse {
	templateId: number;
}

export interface InvoiceTemplate {
	id: Guid;
	name: string;
	description: string;
	dateAssigned: string;
	type: string;
	isDefault: boolean;
	catalogTemplateId: number;
}

export interface BindInvoiceTemplate {
	catalogTemplateId: number;
	brandId: number;
	isDefault: boolean;
}

export interface InvoiceTemplateUpdate {
	isDefault: boolean;
	brandId: number;
	templateId: Guid;
}

export interface InvoiceTemplateAddResponse {
	invoiceTemplateId: Guid;
}

export interface InvoiceTemplatesDelete {
	invoiceTemplatesIds: Guid[];
	brandId: number;
}

export interface TemplatesDelete {
	templatesIds: number[];
}

export interface TemplateAssignment {
	brandId: number;
	brandName: string;
	assignmentDate: string;
}
