import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BusyModule } from './modules/busy';
// import { } from './directives';
// import { } from './components';
// import { } from './dialogs';
// import { } from './guards';
// import { } from './pipes';

const sharedModules = [
	CommonModule,
	FormsModule,
	ReactiveFormsModule,
	BusyModule
];

const sharedDirectives = [
];

const sharedComponents = [
	// components, dialogs, etc...
];

const sharedPipes = [
];

const sharedServices = [
	// services, guards, etc...
];

@NgModule({
	declarations: [
		...sharedDirectives,
		...sharedComponents,
		...sharedPipes
	],
	imports: [
		...sharedModules
	],
	exports: [
		...sharedModules,
		...sharedDirectives,
		...sharedComponents,
		...sharedPipes
	],
	providers: [
		...sharedServices
	]
})
export class SharedModule { }
