import { Injectable } from '@angular/core';
import { HttpAdminAuth } from 'core/auth';
import { AppConfig } from 'tools/AppConfig';
import { Observable } from 'rxjs';
import {
	BindInvoiceTemplate,
	InvoiceTemplate,
	InvoiceTemplateAddResponse,
	InvoiceTemplatesDelete, InvoiceTemplateUpdate,
	Template,
	TemplateAddResponse, TemplateAssignment, TemplatesDelete
} from 'domain/models';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { ApiData, Guid } from 'domain/types';

@Injectable()
export class TemplatesRepository {

	constructor(
		private http: HttpAdminAuth,
		private config: AppConfig
	) {
	}

	getAll(): Observable<Template[]> {
		const requestOptions = {};

		return this.http.get<ApiData<Template[]>>(`${this.config.apiUrl}/admin/templates`, requestOptions)
			.pipe(
				map(res => res.body?.data ?? []),
			);
	}

	addTemplate(template: Template): Observable<TemplateAddResponse> {
		let resp: TemplateAddResponse;

		return this.http.post<ApiData<TemplateAddResponse>>(`${this.config.apiUrl}/admin/template`, template, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body?.data ?? resp),
			);
	}

	getTemplateById(id: number): Observable<Template> {
		const requestOptions = {};

		let template: Template;

		return this.http.get<ApiData<Template>>(`${this.config.apiUrl}/admin/templates/${id}`, requestOptions)
			.pipe(
				map(res => res.body?.data ?? template),
			);
	}

	getTemplateAssignmentsById(id: number): Observable<TemplateAssignment[]> {
		const requestOptions = {};
		return this.http.get<ApiData<TemplateAssignment[]>>(`${this.config.apiUrl}/admin/template-assignments/${id}`, requestOptions)
			.pipe(
				map(res => res.body?.data ?? []),
			);
	}

	getInvoiceTemplateById(brandId: number, id: Guid): Observable<InvoiceTemplate | undefined> {
		return this.getInvoiceTemplatesByBrand(brandId).pipe(map(template => template.find(x => x.id.equals(id))));
	}

	updateTemplate(data: Template): Observable<void> {
		return this.http.put(`${this.config.apiUrl}/admin/templates/${data.id}`, data, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body),
			);
	}

	updateInvoiceTemplate(invoiceTemplateUpdate: InvoiceTemplateUpdate): Observable<void> {
		return this.http.put(`${this.config.apiUrl}/admin/invoice-templates/${invoiceTemplateUpdate.templateId}`,
			invoiceTemplateUpdate, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body),
			);
	}

	getInvoiceTemplatesByBrand(brandId: number): Observable<InvoiceTemplate[]> {
		const requestOptions = {};

		return this.http.get<ApiData<InvoiceTemplate[]>>(`${this.config.apiUrl}/admin/invoice-templates/${brandId}`, requestOptions)
			.pipe(
				map(res => res.body?.data ?? []),
			);
	}

	addInvoiceTemplate(template: BindInvoiceTemplate): Observable<InvoiceTemplateAddResponse> {
		let resp: InvoiceTemplateAddResponse;

		return this.http.post<ApiData<InvoiceTemplateAddResponse>>(`${this.config.apiUrl}/admin/invoice-template`, template, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body?.data ?? resp),
			);
	}

	deleteInvoiceTemplate(invoiceTemplatesDelete: InvoiceTemplatesDelete): Observable<any> {
		return this.http.post(`${this.config.apiUrl}/admin/invoice-templates/delete`, invoiceTemplatesDelete, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body),
			);
	}

	deleteTemplates(templatesDelete: TemplatesDelete): Observable<any> {
		return this.http.post(`${this.config.apiUrl}/admin/templates/delete`, templatesDelete, TemplatesRepository.headersToOptions())
			.pipe(
				map(res => res.body),
			);
	}

	static headersToOptions(): { headers: HttpHeaders; } {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return { headers: headers };
	}

}
