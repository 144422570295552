import { ActionWithLoading, ActionWithLoadingEnded, FlaxAction } from './base-actions';
import {
	BindInvoiceTemplate,
	Brand,
	BrandStatusUpdateData,
	BrandUsers,
	Feed,
	FeedFile,
	FeedLog,
	InvoiceTemplate,
	InvoiceTemplatesDelete,
	InvoiceTemplateUpdate,
	ProvisionBrand,
	Shard,
	Template, TemplateAssignment,
	TemplatesDelete,
	UpdateBrandData
} from 'domain/models';
import { Guid } from 'domain/types';

export enum AppActionTypes {
	LoadAllBrands = '[Brands] Load All Brands',
	LoadAllBrandsSuccess = '[Brands] Load All Brands Success',
	LoadAllBrandsFail = '[Brands] Load All Brands Fail',

	LoadBrand = '[Brand] Load Brand',
	LoadBrandSuccess = '[Brand] Load Brand Success',
	LoadBrandFail = '[Brand] Load Brand Fail',

	LoadShards = '[Shard] Load All Shards',
	LoadShardsSuccess = '[Shard] Load All Shards Success',
	LoadShardsFail = '[Shard] Load All Shards Fail',

	ProvisionNewBrand = '[Brands] Provision Brand',
	ProvisionBrandSuccess = '[Brands] Provision Brand Success',
	ProvisionBrandFail = '[Brands] Provision Brand Fail',
	ClearCreatedBrand = '[Brands] Clear Created Brand',

	UpdateBrand = '[Brands] Update Brand',
	UpdateBrandSuccess = '[Brands] Update Brand Success',
	UpdateBrandFail = '[Brands] Update Brand Fail',

	SetBrandStatus = '[Brands] Set Brand Status',
	SetBrandStatusSuccess = '[Brands] Set Brand Status Success',
	SetBrandStatusFail = '[Brands] Set Brand Status Fail',

	LoadAllFeeds = '[Feeds] Load All Feeds',
	LoadAllFeedsSuccess = '[Feeds] Load All Feeds Success',
	LoadAllFeedsFail = '[Feeds] Load All Feeds Fail',

	LoadLogsByFeed = '[Feed] Load All Logs',
	LoadLogsByFeedSuccess = '[Feed] Load All Logs Success',
	LoadLogsByFeedFail = '[Feed] Load All Logs Fail',

	LoadFeedLog = '[FeedLog] Load Feed Log',
	LoadFeedLogSuccess = '[FeedLog] Load Feed Log Success',
	LoadFeedLogFail = '[FeedLog]Load Feed Log Fail',

	LoadFilesByFeed = '[Feed] Load All Files',
	LoadFilesByFeedSuccess = '[Feed] Load All Files Success',
	LoadFilesByFeedFail = '[Feed] Load All Files Fail',

	LoadFeedFile = '[FeedFile] Load Feed File',
	LoadFeedFileSuccess = '[FeedFile] Load Feed File Success',
	LoadFeedFileFail = '[FeedFile]Load Feed File Fail',

	CloneFeed = '[Feeds] Clone Feed',
	CloneFeedSuccess = '[Feeds] Clone Feed Success',
	CloneFeedFail = '[Feeds] Clone Feed Fail',
	ClearClonedFeed = '[Feeds] Clear Created Feed',

	LoadUsersByBrand = '[Users] Load Users By Brands',
	LoadUsersByBrandSuccess = '[Users] Load Users By Brands Success',
	LoadUsersByBrandFail = '[Users] Load Users By Brands Fail',

	SliceLoading = '[Loadings] Slice Loading',

	LoadAllTemplates = '[Templates] Load All Templates',
	LoadAllTemplatesSuccess = '[Templates] Load All Templates Success',
	LoadAllTemplatesFail = '[Templates] Load All Templates Fail',

	AddNewTemplate = '[Templates] Add New Tamplate',
	ClearCreatedTemplate = '[Templates] Clear Created Template',
	ClearTemplate = '[Templates] Clear Template',
	AddTemplateFail = '[Templates] Add Template Fail',
	AddTemplateSuccess = '[Templates] Add Template Success',

	LoadTemplate = '[Template] Load Template',
	LoadTemplateSuccess = '[Template] Load Template Success',
	LoadTemplateFail = '[Template] Load Template Fail',

	LoadInvoiceTemplate = '[Invoice Template] Load Invoice Template',
	LoadInvoiceTemplateSuccess = '[Invoice Template] Load Invoice Template Success',
	LoadInvoiceTemplateFail = '[Invoice Template] Load Invoice Template Fail',

	ClearInvoiceTemplate = '[Templates] Clear Invoice Template',

	UpdateTemplate = '[Templates] Update Template',
	UpdateTemplateSuccess = '[Templates] Update Template Success',
	UpdateTemplateFail = '[Templates] Update Template Fail',

	LoadInvoiceTemplates = '[Invoice Templates] Load Invoice Templates',
	LoadInvoiceTemplatesSuccess = '[Invoice Templates] Load Invoice Templates Success',
	LoadInvoiceTemplatesFail = '[Invoice Templates] Load Invoice Templates Fail',

	LoadTemplateAssignments = '[Invoice Templates] Load Template Assignments',
	LoadTemplateAssignmentsSuccess = '[Invoice Templates] Load Template Assignments Success',
	LoadTemplateAssignmentsFail = '[Invoice Templates] Load Template Assignments Fail',

	AddInvoiceTemplate = '[Invoice Templates] Add Invoice Template',
	ClearCreatedInvoiceTemplate = '[Templates] Clear Created Invoice Template',
	AddInvoiceTemplateFail = '[Invoice Templates] Add Invoice Template Fail',
	AddInvoiceTemplateSuccess = '[Invoice Templates] Add Invoice Template Success',

	UpdateInvoiceTemplate = '[Invoice Templates] Update Invoice Template',
	UpdateInvoiceTemplateSuccess = '[Invoice Templates] Update Invoice Template Success',
	UpdateInvoiceTemplateFail = '[Invoice Templates] Update Invoice Template Fail',

	DeleteInvoiceTemplate = '[Invoice Templates] Delete Invoice Template',
	DeleteInvoiceTemplateFail = '[Invoice Templates] Delete Invoice Template Fail',
	DeleteInvoiceTemplateFailHandled = '[Invoice Templates] Delete Invoice Template Fail Handled',
	DeleteInvoiceTemplateSuccess = '[Invoice Templates] Delete Invoice Template Success',
	OkDeletedInvoiceTemplate = '[Invoice Templates] Ok Deleted Invoice Template',

	DeleteTemplates = '[Templates] Delete Templates',
	DeleteTemplatesFail = '[Templates] Delete Templates Fail',
	DeleteTemplatesSuccess = '[Templates] Delete Templates Success ',
	OkDeletedTemplate = '[Templates] Ok Deleted Template',
	DeleteTemplateFailHandled= '[Templates] Delete Template Fail Handled'
}

////Brands Actions
export class LoadAllBrands extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadAllBrands;
}

export class LoadAllBrandsSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadAllBrandsSuccess;

	constructor(public brands: Brand[]) {
		super();
	}

}
export class LoadAllBrandsFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadAllBrandsFail;

	constructor(public payload: string) {
		super();
	}
}

export class LoadBrand extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadBrand;

	constructor(public brandId: number) {
		super();
	}
}

export class LoadBrandSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadBrandSuccess;

	constructor(public brand: Brand | undefined) {
		super();
	}
}

export class LoadBrandFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadBrandFail;

	constructor(public payload: string) {
		super();
	}
}

export class ProvisionNewBrand extends FlaxAction {
	readonly type: string = AppActionTypes.ProvisionNewBrand;

	constructor(public brand: ProvisionBrand) {
		super();
	}
}

export class ProvisionBrandSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.ProvisionBrandSuccess;

	constructor(public brand: ProvisionBrand, public newBrand: Brand) {
		super();
	}
}

export class ProvisionBrandFail extends FlaxAction {
	readonly type: string = AppActionTypes.ProvisionBrandFail;

	constructor(public payload: string) {
		super();
	}
}

export class ClearCreatedBrand extends FlaxAction {
	readonly type: string = AppActionTypes.ClearCreatedBrand;
}

export class UpdateBrand extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateBrand;

	constructor(public updateBrandData: UpdateBrandData, public brandId: number) {
		super();
	}
}

export class UpdateBrandSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateBrandSuccess;

	constructor(public updateBrandData: UpdateBrandData) {
		super();
	}
}

export class UpdateBrandFail extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateBrandFail;

	constructor(public updateBrandData: UpdateBrandData) {
		super();
	}
}

export class SetBrandStatus extends FlaxAction {
	readonly type: string = AppActionTypes.SetBrandStatus;

	constructor(public brandStatusUpdateData: BrandStatusUpdateData, public brandId: number) {
		super();
	}
}

export class SetBrandStatusSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.SetBrandStatusSuccess;

	constructor(public brandStatusUpdateData: BrandStatusUpdateData, public brandId: number) {
		super();
	}
}

export class SetBrandStatusFail extends FlaxAction {
	readonly type: string = AppActionTypes.SetBrandStatusFail;

	constructor(public brandStatusUpdateData: BrandStatusUpdateData, public brandId: number) {
		super();
	}
}

////Load Users BrandId
export class LoadUsersByBrand extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadUsersByBrand;

	constructor(public id: number) {
		super();
	}
}

export class LoadUsersByBrandSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadUsersByBrandSuccess;

	constructor(public brandUsers: BrandUsers[], public brandId: number) {
		super();
	}
}

export class LoadUsersByBrandFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadUsersByBrandFail;

	constructor(public payload: string) {
		super();
	}
}

// Shards Actions
export class LoadShards extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadShards;
}

export class LoadShardsSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadShardsSuccess;

	constructor(public shards: Shard[]) {
		super();
	}
}

export class LoadShardsFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadShardsFail;

	constructor(public payload: string) {
		super();
	}
}

// Feeds Actions
export class LoadAllFeeds extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadAllFeeds;
}

export class LoadAllFeedsSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadAllFeedsSuccess;

	constructor(public feeds: Feed[]) {
		super();
	}
}

export class LoadAllFeedsFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadAllFeedsFail;

	constructor(public payload: string) {
		super();
	}
}

//// Feed Logs

export class LoadLogsByFeed extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadLogsByFeed;

	constructor(public id: number) {
		super();
	}
}

export class LoadLogsByFeedSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadLogsByFeedSuccess;

	constructor(public feedLogs: FeedLog[], public feedId: number) {
		super();
	}
}

export class LoadLogsByFeedFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadLogsByFeedFail;

	constructor(public payload: string) {
		super();
	}
}

export class LoadFeedLog extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadFeedLog;

	constructor(public feedId: number, public logId: number) {
		super();
	}
}

export class LoadFeedLogSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadFeedLogSuccess;

	constructor(public feedLog: FeedLog) {
		super();
	}
}

export class LoadFeedLogFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadFeedLogFail;

	constructor(public payload: string) {
		super();
	}
}

//// Feed Files

export class LoadFilesByFeed extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadFilesByFeed;

	constructor(public id: number) {
		super();
	}
}

export class LoadFilesByFeedSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadFilesByFeedSuccess;

	constructor(public feedFiles: FeedFile[], public feedId: number) {
		super();
	}
}

export class LoadFilesByFeedFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadFilesByFeedFail;

	constructor(public payload: string) {
		super();
	}
}

export class LoadFeedFile extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadFeedFile;

	constructor(public feedId: number, public fileId: number) {
		super();
	}
}

export class LoadFeedFileSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadFeedFileSuccess;

	constructor(public feedFile: FeedFile) {
		super();
	}
}

export class LoadFeedFileFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadFeedFileFail;

	constructor(public payload: string) {
		super();
	}
}

//// Clone Feed
export class CloneFeed extends FlaxAction {
	readonly type: string = AppActionTypes.CloneFeed;

	constructor(public feedId: number, public feed: Feed) {
		super();
	}
}

export class CloneFeedSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.CloneFeedSuccess;

	constructor(public feed: Feed) {
		super();
	}
}

export class CloneFeedFail extends FlaxAction {
	readonly type: string = AppActionTypes.CloneFeedFail;

	constructor(public payload: string) {
		super();
	}
}

export class ClearClonedFeed extends FlaxAction {
	readonly type: string = AppActionTypes.ClearClonedFeed;
}

//// Slice Loading
export class SliceLoading extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.SliceLoading;
}

//// Templates Loading
export class LoadAllTemplates extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadAllTemplates;
}

export class LoadAllTemplatesSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadAllTemplatesSuccess;

	constructor(public templates: Template[]) {
		super();
	}
}

export class LoadAllTemplatesFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadAllTemplatesFail;

	constructor(public payload: string) {
		super();
	}
}

export class AddNewTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.AddNewTemplate;

	constructor(public template: Template) {
		super();
	}
}

export class AddTemplateFail extends FlaxAction {
	readonly type: string = AppActionTypes.AddTemplateFail;

	constructor(public error: any) {
		super();
	}
}

export class AddTemplateSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.AddTemplateSuccess;

	constructor(public templateId: number) {
		super();
	}
}

export class LoadTemplate extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadTemplate;

	constructor(public templateId: number) {
		super();
	}
}

export class LoadTemplateSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadTemplateSuccess;

	constructor(public template: Template | undefined) {
		super();
	}
}

export class LoadTemplateFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadTemplateFail;

	constructor(public payload: string) {
		super();
	}
}

export class LoadInvoiceTemplate extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadInvoiceTemplate;

	constructor(public brandId: number, public templateId: Guid) {
		super();
	}
}

export class LoadTemplateAssignments extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadTemplateAssignments;

	constructor(public templateId: number) {
		super();
	}
}

export class LoadTemplateAssignmentsSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadTemplateAssignmentsSuccess;

	constructor(public templateAssignments: TemplateAssignment[] | undefined) {
		super();
	}
}

export class LoadTemplateAssignmentsFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadTemplateAssignmentsFail;

	constructor(public payload: string) {
		super();
	}
}

export class LoadInvoiceTemplateSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadInvoiceTemplateSuccess;

	constructor(public template: InvoiceTemplate | undefined) {
		super();
	}
}

export class LoadInvoiceTemplateFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadInvoiceTemplateFail;

	constructor(public payload: string) {
		super();
	}
}

export class ClearCreatedTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.ClearCreatedTemplate;
}

export class OkDeletedTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.OkDeletedTemplate;
}

export class DeleteTemplateFailHandled extends FlaxAction {
	readonly type: string = AppActionTypes.DeleteTemplateFailHandled;
}

export class ClearTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.ClearTemplate;
}

export class ClearCreatedInvoiceTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.ClearCreatedInvoiceTemplate;
}

export class OkDeletedInvoiceTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.OkDeletedInvoiceTemplate;
}

export class DeleteInvoiceTemplateFailHandled extends FlaxAction {
	readonly type: string = AppActionTypes.DeleteInvoiceTemplateFailHandled;
}

export class UpdateTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateTemplate;

	constructor(public updateTemplateData: Template) {
		super();
	}
}

export class UpdateTemplateSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateTemplateSuccess;

	constructor(public updateTemplateData: Template) {
		super();
	}
}

export class UpdateTemplateFail extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateTemplateFail;

	constructor(public error: any) {
		super();
	}
}

export class LoadInvoiceTemplates extends ActionWithLoading {
	readonly type: string = AppActionTypes.LoadInvoiceTemplates;

	constructor(public brandId: number) {
		super();
	}
}

export class LoadInvoiceTemplatesSuccess extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadInvoiceTemplatesSuccess;

	constructor(public invoiceTemplates: InvoiceTemplate[]) {
		super();
	}
}

export class LoadInvoiceTemplatesFail extends ActionWithLoadingEnded {
	readonly type: string = AppActionTypes.LoadInvoiceTemplatesFail;

	constructor(public payload: string) {
		super();
	}
}

export class AddInvoiceTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.AddInvoiceTemplate;

	constructor(public bindInvoiceTemplate: BindInvoiceTemplate) {
		super();
	}
}

export class UpdateInvoiceTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateInvoiceTemplate;

	constructor(public invoiceTemplateUpdate: InvoiceTemplateUpdate) {
		super();
	}
}

export class UpdateInvoiceTemplateSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateInvoiceTemplateSuccess;

	constructor() {
		super();
	}
}

export class UpdateInvoiceTemplateFail extends FlaxAction {
	readonly type: string = AppActionTypes.UpdateInvoiceTemplateFail;

	constructor(public payload: string) {
		super();
	}
}

export class AddInvoiceTemplateFail extends FlaxAction {
	readonly type: string = AppActionTypes.AddInvoiceTemplateFail;

	constructor(public payload: string) {
		super();
	}
}

export class AddInvoiceTemplateSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.AddInvoiceTemplateSuccess;

	constructor(public invoiceTemplateId: Guid) {
		super();
	}
}

export class DeleteInvoiceTemplate extends FlaxAction {
	readonly type: string = AppActionTypes.DeleteInvoiceTemplate;

	constructor(public invoiceTemplatesDelete: InvoiceTemplatesDelete) {
		super();
	}
}

export class DeleteInvoiceTemplateFail extends FlaxAction {
	readonly type: string = AppActionTypes.DeleteInvoiceTemplateFail;

	constructor(public error: any) {
		super();
	}
}

export class DeleteInvoiceTemplateSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.DeleteInvoiceTemplateSuccess;

	constructor() {
		super();
	}
}

export class DeleteTemplates extends FlaxAction {
	readonly type: string = AppActionTypes.DeleteTemplates;

	constructor(public templatesDelete: TemplatesDelete) {
		super();
	}
}

export class DeleteTemplatesFail extends FlaxAction {
	readonly type: string = AppActionTypes.DeleteTemplatesFail;

	constructor(public error: any) {
		super();
	}
}

export class DeleteTemplatesSuccess extends FlaxAction {
	readonly type: string = AppActionTypes.DeleteTemplatesSuccess;

	constructor() {
		super();
	}
}

// Union the valid types
export type AppActions = LoadAllBrands
	| LoadAllBrandsSuccess
	| LoadAllBrandsFail
	| LoadBrand
	| LoadBrandSuccess
	| LoadBrandFail
	| LoadShards
	| LoadShardsSuccess
	| LoadShardsFail
	| LoadUsersByBrand
	| ClearCreatedBrand
	| ProvisionNewBrand
	| ProvisionBrandSuccess
	| ProvisionBrandFail
	| LoadUsersByBrandSuccess
	| LoadUsersByBrandFail
	| LoadAllFeeds
	| LoadAllFeedsSuccess
	| LoadAllFeedsFail
	| LoadLogsByFeed
	| LoadLogsByFeedSuccess
	| LoadLogsByFeedFail
	| LoadFeedLog
	| LoadFeedLogSuccess
	| LoadFeedLogFail
	| LoadFilesByFeed
	| LoadFilesByFeedSuccess
	| LoadFilesByFeedFail
	| LoadFeedFile
	| LoadFeedFileSuccess
	| LoadFeedFileFail
	| CloneFeedSuccess
	| CloneFeed
	| CloneFeedFail
	| ClearClonedFeed
	| SliceLoading
	| LoadAllTemplates
	| LoadAllTemplatesSuccess
	| LoadAllTemplatesFail
	| AddNewTemplate
	| AddTemplateSuccess
	| AddTemplateFail
	| LoadTemplate
	| LoadTemplateSuccess
	| LoadTemplateFail
	| LoadInvoiceTemplate
	| LoadInvoiceTemplateSuccess
	| LoadInvoiceTemplateFail
	| ClearCreatedTemplate
	| UpdateTemplate
	| UpdateTemplateSuccess
	| UpdateTemplateFail
	| LoadInvoiceTemplates
	| LoadInvoiceTemplatesSuccess
	| LoadInvoiceTemplatesFail
	| AddInvoiceTemplate
	| DeleteInvoiceTemplate
	| DeleteInvoiceTemplateFail
	| DeleteInvoiceTemplateSuccess
	| DeleteTemplates
	| DeleteTemplatesFail
	| DeleteTemplatesSuccess
	;
