import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of, throwError, ReplaySubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Router } from '@angular/router';

import jwt_decode from 'jwt-decode';
import { AppConfig } from 'tools/AppConfig';

interface RequestTokenOptions {
	headers: HttpHeaders;
	params: HttpParams;
}

@Injectable()
export class AdminAuthService {
	readonly apiUrl: string = '';

	readonly cliendId: string = '6';
	readonly cliendSecret: string = 'notasecret';
	private isRefreshLocked = false;
	private refreshResult: Observable<AdminAuthDetails> = of();
	private refreshResultId: string;

	constructor(
		private http: HttpClient,
		private config: AppConfig
	) {
	}

	public get adminAuthDetails(): AdminAuthDetails | undefined {
		const details = this.loadAdminAuthDetails();
		return details;
	}

	public requestToken(options: RequestTokenOptions): Observable<AdminAuthDetails> {

		return this.http.post<any>(`${this.config.apiUrl}/token`, undefined, options)
			.pipe(
				map(data => {
					const token = data.access_token;
					const secondsToExpire = data.expires_in;
					const refreshToken = data.refresh_token;
					const expirationDate: Date = new Date();
					expirationDate.setTime(expirationDate.getTime() + secondsToExpire * 1000);

					const auth = new AdminAuthDetails(token, expirationDate, refreshToken);
					this.saveAdminAuthDetails(auth);
					return auth;
				})
			);
	}

	public adminLogin(username: string, password: string): Observable<AdminAuthDetails> {
		const body = new HttpParams()
			.set('grant_type', 'password')
			.set('client_id', this.cliendId)
			.set('client_secret', this.cliendSecret)
			.set('username', username)
			.set('password', password);
		const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
		const options = {
			headers: headers,
			params: body
		};

		return this.requestToken(options);
	}

	private saveAdminAuthDetails(auth: AdminAuthDetails): void {
		localStorage.setItem('admin-auth', JSON.stringify(auth));
	}

	private loadAdminAuthDetails(): AdminAuthDetails | undefined {
		const value = localStorage.getItem('admin-auth');

		if (!value) {
			return undefined;
		}

		return AdminAuthDetails.restoreFromJson(value);
	}

	private removeAdminAuthDetails(): void {
		localStorage.removeItem('admin-auth');
	}

	public signout(): void {
		this.removeAdminAuthDetails();
	}

	public refresh(timestamp: string): Observable<AdminAuthDetails> {
		// allow only one refresh at a time
		if (!this.isRefreshLocked) {
			this.isRefreshLocked = true;
			const subj = new ReplaySubject<AdminAuthDetails>(1);

			this.refreshResultId = new Date().getUTCMinutes().toString() + '.' + new Date().getUTCSeconds().toString() + '.' + new Date().getUTCMilliseconds().toString();
			this.refreshResult = subj.asObservable();

			const storageAuth = localStorage.getItem('admin-auth');
			if (!storageAuth)
				return throwError(() => new Error(`Cannot find admin-auth in local storage!`));
			const adminAuthDetails = <AdminAuthDetails>JSON.parse(storageAuth);

			if (!adminAuthDetails) {
				this.isRefreshLocked = false;
				return throwError(() => new Error('Cannot refresh. Auth data doesn\'t exist.'));
			}

			const body = new HttpParams()
				.set('grant_type', 'refresh_token')
				.set('client_id', this.cliendId)
				.set('client_secret', this.cliendSecret)
				.set('refresh_token', adminAuthDetails.refreshToken);

			const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
			const options = { headers: headers, params: body };

			this.requestToken(options).pipe(
				catchError(err => {
					return of();
				}))
				.subscribe(result => {
					subj.next(result);
					subj.complete();
					this.isRefreshLocked = false;
				});

			return this.refreshResult;
		} else {
			return this.refreshResult;
		}
	}
}

export class AdminAuthDetails {

	public username: string;
	public displayName: string;
	public userId: number;
	public brandId: number;
	public brandName: string;

	public roles: string[];

	constructor(public token: string,
		public expirationDate: Date,
		public refreshToken: string) {

		const decoded = jwt_decode<any>(token);

		this.username = decoded.unique_name;
		this.displayName = decoded.DisplayName;
		this.userId = Number(decoded.nameid);
		this.brandId = Number(decoded.BrandId);
		this.brandName = decoded.BrandName;
		this.roles = decoded.roles;
	}

	static restoreFromJson(json: string): AdminAuthDetails {
		const auth = JSON.parse(json);
		return new AdminAuthDetails(
			auth.token,
			new Date(Date.parse(auth.expirationDate)),
			auth.refreshToken
		);
	}

	public get expired(): boolean {
		return this.expirationDate < new Date();
	}
}

@Injectable()
export class AdminAuthRouteGuard  {
	constructor(private adminAuthService: AdminAuthService, private router: Router) {
	}

	private redirectToLogin(): void {
		void this.router.navigate(['/log-in']);
	}

	canActivate(): Observable<boolean> {

		const adminAuthDetails = this.adminAuthService.adminAuthDetails;

		if (!adminAuthDetails) {
			this.redirectToLogin();
			return of(false);
		}

		if (adminAuthDetails && !adminAuthDetails.expired){
			return of(true);
		}

		return this.adminAuthService.refresh('guard')
			.pipe(map(result => {
				if (result) {
					return true;
				} else {
					this.redirectToLogin();
					return false;
				}
			}));
	}
}
