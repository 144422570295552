import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Brand, BrandUsers, ProvisionValidate, ProvisionBrand, Shard, UpdateBrandData, BrandStatusUpdateData } from 'domain/models';
import { HttpAdminAuth } from 'core/auth';
import { HttpHeaders } from '@angular/common/http';
import { AppConfig } from 'tools/AppConfig';
import { ApiData } from 'domain/types';

interface RequestOptions {
	headers: HttpHeaders;
}

@Injectable()
export class BrandsRepository {

	constructor(
		private http: HttpAdminAuth,
		private config: AppConfig
	) {
	}

	getAll(): Observable<Brand[]> {
		return this.http.get<ApiData<Brand[]>>(`${this.config.apiUrl}/distributor-admin/brands`)
			.pipe(
				map(res => res.body?.data ?? [])
			);
	}

	getAllshards(): Observable<Shard[]> {
		return this.http.get(`${this.config.apiUrl}/admin/shards`)
			.pipe(
				map(res => res.body)
			);
	}

	getBrandById(id: number): Observable<Brand | undefined> {
		return this.getAll().pipe(map(brand => brand.find(x => x.id === id)));
	}

	getUsersById(brandId: number): Observable<BrandUsers[]> {
		return this.http.get<ApiData<BrandUsers[]>>(`${this.config.apiUrl}/distributor-admin/brands/${brandId}/users`)
			.pipe(
				map(res => res.body?.data ?? [])
			);
	}

	public async loginAsResellerUser(userId: number): Promise<string | undefined> {
		return lastValueFrom(this.http.get(`${this.config.apiUrl}/distributor-admin/portal/${userId}/loginas`)
			.pipe(
				map(res => {
					return res.body.data.toString();
				})
			), { defaultValue: undefined });
	}

	public adminProvisionValidate(value: ProvisionValidate[]): Observable<any> {
		return this.http.post(`${this.config.apiUrl}/admin/provision/validate`, { records: value }, BrandsRepository.headersToOptions())
			.pipe(
				map(res => res.body)
			);
	}

	public createReseller(brand: ProvisionBrand): Observable<any> {
		return this.http.post(`${this.config.apiUrl}/distributor-admin/brands`, brand, BrandsRepository.headersToOptions())
			.pipe(
				map(res => res.body)
			);
	}

	public updateReseller(updateBrandData: UpdateBrandData, brandId: number): Observable<any> {
		return this.http.put(`${this.config.apiUrl}/distributor-admin/brands/${brandId}`, updateBrandData, BrandsRepository.headersToOptions())
			.pipe(
				map(res => res.body)
			);
	}

	public setBrandStatus(brandStatusUpdateData: BrandStatusUpdateData, brandId: number): Observable<any> {
		return this.http.put(`${this.config.apiUrl}/admin/brand/${brandId}/status`, brandStatusUpdateData, BrandsRepository.headersToOptions());
	}

	private static headersToOptions(): RequestOptions {
		const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
		return { headers: headers };
	}
}
