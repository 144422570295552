
export interface ICustomErrors {
	label: string;
	missingTemplates?: IMissingTemplates[];
	priceInconsistencies?: IPriceInconsistencies[];
}

export interface IMissingTemplates {

	code: string;
	title: string;
}

export interface IPriceInconsistencies {

	code: string;
	title: string;
	importedPrice: string;
	correspondingPrice: string;
}
